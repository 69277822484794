import { API_URL, NEW_API_URL, CAMPESTRE_API } from '@/config/config';
import axios, { AxiosResponse } from 'axios';
import useAuth from './useAuth';
import { IResponse } from '@/interfaces/IResponse';

const instances = {
    old: axios.create({
        baseURL: API_URL,
        transitional: {
            silentJSONParsing: false,
            forcedJSONParsing: false,
            clarifyTimeoutError: false,
        },
        responseType: 'json',
    }),
    new: axios.create({
        baseURL: NEW_API_URL,
        transitional: {
            silentJSONParsing: false,
            forcedJSONParsing: false,
            clarifyTimeoutError: true,
        },
        responseType: 'json',
        timeout: 0,
    }),
    campestre: axios.create({
        baseURL: CAMPESTRE_API,
        responseType: 'json',
    }),
};

export default function useAxios() {
    axios.defaults.baseURL = API_URL;

    const setAuthorization = (token: any) => {
        instances.old.defaults.headers.common[
            'Authorization'
        ] = `Bearer ${token}`;
        instances.new.defaults.headers.common[
            'Authorization'
        ] = `Bearer ${token}`;
    };

    const file = async (
        endpoint: string,
        protocol: 'GET' | 'POST' | 'PUT' | 'DELETE',
        params: any = {},
        useOldApi: boolean = true
    ) => {
        const { logout } = useAuth();
        let request: AxiosResponse | undefined = undefined;
        try {
            const instance = useOldApi ? instances.old : instances.new;
            switch (protocol) {
                case 'GET': {
                    request = await instance.get(endpoint, {
                        responseType: 'blob',
                        params:{ ...params }
                    });
                    break;
                }
                case 'POST': {
                    request = await instance.post(endpoint, params, {
                        responseType: 'blob',
                    });
                    break;
                }
                case 'PUT': {
                    request = await instance.put(endpoint, params, {
                        responseType: 'blob',
                    });
                    break;
                }
                case 'DELETE': {
                    request = await instance.delete(endpoint, {
                        responseType: 'blob',
                        params:{ ...params }
                    },);
                    break;
                }
            }
            if (request?.data.reauth) {
                logout();
            }
            return {
                data: request?.data,
            };
        } catch (err) {
            return {
                data: null,
            };
        }
    };

    const get = async (endpoint: string, useOldApi = true) => {
        const { logout } = useAuth();
        try {
            const instance = useOldApi ? instances.old : instances.new;
            const { data } = await instance.get(endpoint);
            if (data.reauth) {
                logout();
            }
            return {
                data,
            };
        } catch (err) {
            return {
                data: null,
            };
        }
    };

    const post = async (endpoint: string, params: any, useOldApi = true) => {
        const { logout } = useAuth();
        try {
            const instance = useOldApi ? instances.old : instances.new;
            const { data } = await instance.post(endpoint, params, {
                responseType: 'json',
            });

            // console.log(data);

            if (data.reauth) {
                logout();
            }
            return {
                data,
            };
        } catch (err) {
            return {
                data: {
                    error: true,
                    message: 'Error al conectar con el servidor',
                },
            };
            // return {
            //     data: null
            // };
        }
    };

    const make = async<T = any, K = any>(
        endpoint: string,
        protocol: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
        params?: K
    ) => {
        const { logout } = useAuth();
        let request: AxiosResponse | undefined = undefined;
        try {
            switch (protocol) {
                case 'GET': {
                    request = await instances.new.get(endpoint, {
                        responseType: 'json',
                        params:{ ...params }
                    });
                    break;
                }
                case 'POST': {
                    request = await instances.new.post(endpoint, params, {
                        responseType: 'json',
                    });
                    break;
                }
                case 'PUT': {
                    request = await instances.new.put(endpoint, params, {
                        responseType: 'json',
                    });
                    break;
                }
                case 'PATCH': {
                    request = await instances.new.patch(endpoint, params, {
                        responseType: 'json',
                    });
                    break;
                }
                case 'DELETE': {
                    request = await instances.new.delete(endpoint, {
                        responseType: 'json',
                        params:{ ...params }
                    },);
                    break;
                }
            }

            if (request?.data.reauth) {
                logout();
            }

            return {
                data: { 
                    ...request?.data
                } as IResponse<T>,
            };
        } catch (e){
            
            return {
                data: {
                    error: true,
                    message: 'Error al conectar con el servidor'+ e,
                } as IResponse<T>,
            };
        }
    };

    const campestre_api = async (endpoint: string, params?: object) => {
        try {
            const request = await instances.campestre.get(endpoint, {
                responseType: 'json',
                params,
            });
            return {
                data: { ...request?.data },
            };
        } catch {
            return {
                data: {
                    error: true,
                    message: 'Error al conectar con el servidor',
                },
            };
        }
    };

    return {
        file,
        get,
        post,
        setAuthorization,
        campestre_api,
        make,
    };
}

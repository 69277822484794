<template>
  <transition name="fade">
    <div class="loading-container" v-if="isLoading || count > 0">
      <div class="loading-container__wrapper">
        <img src="../../assets/logo-campestre-light.svg" alt="Logo Club Campestre" />
        <p>{{ message }}</p>
      </div>
    </div>
  </transition>
</template>
<script>
import { useLoading } from "@/components/loading/useLoading";

export default {
  name: "Loading",
  setup() {
    const { message, isLoading, count } = useLoading();
    return {
      message,
      isLoading,
      count
    };
  },
};
</script>
<style lang="scss" scoped>
.loading-container {
  align-items: center;
  background: rgba(9,36,54,.85);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 999999;
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 220px;
      margin: o auto;
      margin-bottom: 1em;
    }
    p {
      color: white;
      font-size: 1rem;
      text-align: center;
      text-transform: capitalize;
    }
  }
}

.fade {
  &-enter {
    &-from {
      opacity: 0;
    }
    &-to {
      opacity: 1;
    }
    &-active {
      transition: all 0.15s ease;
    }
  }
  &-leave {
    &-from {
      opacity: 1;
    }
    &-to {
      opacity: 0;
    }
    &-active {
      transition: all 0.35s ease;
    }
  }
}
</style>
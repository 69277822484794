import { reactive, toRefs } from "vue";

const state = reactive({
    isLoading: false,
    message: '',
    count:0
});

export const useLoading = () => {
    const show = (message: string = '') => {
        state.count++
        state.isLoading = true;
        state.message = message;
        // console.log(state.count)
    }
    const hide = () => {
        //Evitamos que baje a valores de -1
        if(state.count > 0)
            state.count--
        
        // console.log(state.count)
        state.isLoading = false;
    }

    return {
        show,
        hide,
        ...toRefs(state)
    }
}
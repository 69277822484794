import AppUtils from "@/AppUtils";
import moment from "moment";
import { IDepartamentoV2 } from "./IDepartamento";

interface IPermiso {
    padre: String;
    permiso: String;
}

export class Permiso implements IPermiso{
    padre = "";
    permiso = "";
    constructor(permiso?:Permiso){
        if(permiso){
            Object.assign(this, permiso)
        }
    }
    
}

interface IUser {
    id_user?: String;
    email: String;
    username: String;
    name: String;
    lastname: String;
    fotografia?: Object;
    apellido_materno?: String;
    cargo: String;
    correo_personal?: String;
    departamento: String;
    descripcion?: String;
    fecha_nacimiento: String;
    perfil: String;
    password?: String;
    password_confirmation?: String;
}

export default class User implements IUser {
    email = '';
    username = '';
    name = '';
    lastname = '';
    role = '';
    cargo = '';
    departamento = '';
    fecha_nacimiento = '';
    perfil = '';
    fotografia = {
        id_archivo: '',
        file: []
    };

    constructor(user?: User) {
        if (user) {
            Object.assign(this, user);
        }
    }
}


interface IAuth{
    id_user: String
    username: String
    name: String
    lastname: String
    role: String
    email: String
    perfil: String
    departamento: null | IDepartamentoV2
    fotografia_path: String
    isInitialised:Boolean
    permisos: Array<Permiso>
}

export class Auth implements IAuth{
    id_user = "";
    username = "";
    name = "";
    lastname = "";
    role = "";
    email = "";
    perfil = "";
    fotografia_path = "";
    departamento: null | IDepartamentoV2 = null
    isInitialised =  false;
    permisos = [];
    constructor(auth?:Auth){
        if(auth){
            Object.assign(this, auth)
            this.isInitialised = true
        }else{
            this.isInitialised = false
        }
    }
    
}

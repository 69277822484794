type titleTypes = 'success' | 'error' | 'warning';
type dateTypes = 'user' | 'server';
import moment from 'moment';
import { useRouter } from 'vue-router';
import { DOCUMENTS_URL } from './config/config';
import { IGlobalVariable } from './interfaces/IGlobalVariable';
import { Permiso } from './interfaces/IUser';
import useAuth from './use/useAuth';
import useAxios from './use/useAxios';
const { make } = useAxios()

const { user } = useAuth()
const router = useRouter();
const AppUtils = {
    allowedMasks: {
        id_socio: '####-##-##',
    },
    getDateConfig(dateType: dateTypes = 'user'): string {
        return dateType === 'user' ? 'DD/MM/yyyy' : 'YYYY-MM-DD';
    },
    getDateTimeConfig(dateType: dateTypes = 'user'): string {
        return dateType === 'user' ? 'DD/MM/yyyy HH:mm:ss' : 'YYYY-MM-DD HH:mm:ss';
    },
    familyTypes:[1,2,3],
    getTitleAlert(typeTitle: titleTypes): string {
        switch (typeTitle) {
            case 'success':
                return 'Operación exitosa';
            case 'error':
                return 'Algo ha salido mal';
            case 'warning':
                return '';
            default:
                return '';
        }
    },
    assignPhoto(error: any, file: any) {
        if (error) {
            console.error('Error Processing File');
            return;
        }
        return {
            id_archivo: file.serverId,
            file: [
                {
                    source: file.serverId,
                    options: {
                        type: 'local',
                    },
                },
            ],
        };
    },
    unassignPhoto(files: Array<any>, current: object) {
        if (files.length <= 0) {
            return {
                id_archivo: '',
                file: [],
            };
        }
        return current;
    },
    assetUrl:(assetRoute:string) => `${DOCUMENTS_URL}${assetRoute}`,
    openFile(assetRoute: string) {
        const url = `${DOCUMENTS_URL}${assetRoute}`;
        const a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    },
    downloadFileServe(assetRoute: string) {
        const url = `${DOCUMENTS_URL}${assetRoute}`;
        const a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        a.setAttribute('download', assetRoute);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    },
    getMonths() {
        moment.locale('es');
        const prepareDate = (index: number) => {
            const month = (index + 1).toLocaleString('es-MX', {
                minimumIntegerDigits: 2,
                useGrouping: false,
            });
            const currentYear = moment().format('YYYY').toString();
            return `${currentYear}-${month}`;
        };
        return moment.months().map((el, index) => ({
            name: el,
            value: prepareDate(index),
        }));
    },
    downloadBlob(data: Blob, extension: string, nameFile = 'downloaded') {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(data);
        link.download = `${nameFile}.${extension}`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    },
    downloadFile(url: string) {
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.download = url
        link.click();
    },
    getGlobalVariable: async (slug:string) : Promise<IGlobalVariable| null|undefined> => {
        const {
            data:{
                error,
                data
            }
        } = await make<IGlobalVariable>('global-variables/'+slug,'GET')
        return error ? null : data
    },
    sumAmmounts: (prop:string) => {
        return (acc:number, el:any) => {
            acc += Number(el[prop] ?? 0)
            return acc
        }
    },
    userCan: (padre: string, permiso: string | string[]) => {
        if(typeof permiso === 'string'){
            return user.value.permisos.find((e: Permiso) => e.padre === padre && e.permiso === permiso) ? true : false;
        }else{
            return user.value.permisos.filter((e:Permiso) => e.padre === padre && permiso.includes(e.permiso)).length > 0 
        }
    },
    calculateIva: (monto: number) => {
        return (Number(monto) * 0.16).toFixed(2)
    },
    calculateImpuesto: (monto: number,impuesto: number) => {
        const tasa = impuesto == 0 ? 0 : impuesto / 100;
        return (Number(monto) * tasa).toFixed(2);
    }
};

export default AppUtils;

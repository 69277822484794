<template>
  <Loading></Loading>
  <router-view></router-view>
</template>

<script>
import Loading from "@/components/loading/Loading"
export default {
  components: {
    Loading,
  },
  setup(){
    return { }
  }
};
</script>


<style lang="scss" src="../public/dashlite/scss/dashlite.scss"></style>
<style lang="scss">
    .multiselect__tags {
      min-height: calc(2.125rem + 2px) !important;
      border: 0px !important;
    }
    .multiselect .multiselect__placeholder{
      padding-top: 0px !important;
    }
    .multiselect {
        min-height: calc(2.125rem + 2px) !important;
        
        background-color: #fff !important;
        background-clip: padding-box !important;
        border: 1px solid #dbdfea !important;
        border-radius: 4px !important;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
    }
    .multiselect__select{
      min-height: calc(2.125rem + 2px) !important;
    }
    .multiselect__input, .multiselect__single{
      margin-bottom: 0px !important;
    }

    .aspect-ratio{
        &-1{
            aspect-ratio: 1 / 1;
        }
    }

    .img-cover{
        object-fit: cover;
    }

    .pop-up-fade{
      transform: translate(0, -10px);;
    }
    .pop-up-zoom{
      transform: scale(.95) ;
      transition: transform .3s ease-out;
    }
    .btn-collapse-child{
      border-radius: 4px;
      height: 15px;
      width: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .fade {
      &-enter {
        &-from {
          opacity: 0;
        }
        &-to {
          opacity: 1;
        }
        &-active {
          transition: all 0.15s ease;
        }
      }
      &-leave {
        &-from {
          opacity: 1;
        }
        &-to {
          opacity: 0;
        }
        &-active {
          transition: all 0.15s ease;
        }
      }
    }

    .data-table-sticky{
      position: sticky;
      inset-block-start: 0;
      z-index: 10;
    }
    .data-table-tfoot-sticky{
      position: sticky;
      inset-block-start: 0;
      z-index: 10;
      bottom: 0;
    }
    .pointer{
      cursor: pointer;
    }
    .vc-popover-content-wrapper{
      z-index: 100 !important;
    }
</style>


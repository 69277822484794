import { reactive, toRefs } from '@vue/reactivity';
import { AxiosResponse } from 'axios';
import useAxios from './useAxios';

import { storage } from '@/config/storage';
import { Auth, Permiso } from '@/interfaces/IUser';
import { computed } from 'vue';

const state = reactive({
    token: null,
    user: new Auth(),
    init: false,
    isSuperAdmin: {},
});

export default function useAuth() {
    const { post, setAuthorization } = useAxios();

    const validateToken = () => {
        return new Promise((resolve) => {
            if (state.token === null) {
                state.init = true;
                resolve(false);
            } else {
                !state.init &&
                    post('users/validate', { token: state.token }).then(
                        ({ data }) => {
                            if (data.error) {
                                logout();
                            } else {
                                state.user = new Auth(data.user);
                            }
                            state.init = true;
                            resolve(true);
                        }
                    );
            }
        });
    };

    state.isSuperAdmin = computed(() => {
        const superRoles = [
            'superadmin',
            '1be6be1c-f46b-11eb-b8a2-0242ac130002',
        ];
        return superRoles.includes(state.user.perfil);
    });

    const isAdmin = () => {
        const superRoles = [
            'superadmin',
            '1be6be1c-f46b-11eb-b8a2-0242ac130002',
        ];
        return superRoles.includes(state.user.perfil);
    };

    const recoveryUser = (formData: any) => {
        return post('users/request-recovery', formData);
    };

    const setToken = (token: any) => {
        state.token = token;
        return validateToken();
    };

    const login = (formData: any): any => {
        return post('users/login', formData, false);
    };

    const setPasswordRecovery = (formData: any) => {
        return post('users/recovery-password', formData);
    };

    const setSession = (token: any, user: any) => {
        setAuthorization(token);
        storage.setItem('token', token);
        state.token = token;
        state.user = new Auth(user);
    };

    const logout = () => {
        storage.clear();
        state.token = null;
        state.user = new Auth();
    };

    const userCan = (padre: string, permiso: string) => {
        return state.user.permisos.find((e: Permiso) => e.padre.toLowerCase() === padre.toLowerCase() && e.permiso.toLowerCase() === permiso.toLowerCase()) ? true : false;
    };

    return {
        ...toRefs(state),
        login,
        logout,
        setSession,
        setToken,
        recoveryUser,
        setPasswordRecovery,
        isAdmin,
        userCan
    };
}
